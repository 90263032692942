// dep
import { NgModule, VERSION, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { FirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ClipboardModule } from "@angular/cdk/clipboard";
import { datadogRum } from '@datadog/browser-rum';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { NgxStripeModule, StripeService } from 'ngx-stripe';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ColorPickerModule } from 'ngx-color-picker';

import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';

// app
import { AppConfigService } from './app.config';

import { environment } from "@environment";

import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { ConfirmComponent } from './components/confirm.component';
import { AlertComponent } from './components/alert.component';
// import { SpinnerComponent } from './components/spinner.component';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';

import { isEmptyPipe } from './pipes/is-empty.pipe';
import { OTimeAgoPipe } from './pipes/o-time-ago.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';

import { WhiteLabelService } from './services/white-label.service';
import { AuthService } from './services/auth.service';
import { AuthProxyService } from './services/auth.proxy.service'
import { UserService } from './services/user.service';
import { FirestoreService } from './services/firestore.service';

import { HttpAuthInterceptor } from './interceptors/http-auth.interceptor';
import { HttpDomainInterceptor } from './interceptors/http-domain.interceptor';

// import { TextReaderComponent } from './pages/text-reader/text-reader.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardAdminComponent } from './pages/dashboard-admin/dashboard-admin.component';
import { HeaderComponent } from './pages/header/header.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { FormInputUploadComponent } from './pages/form-input-upload/form-input-upload.component';
import { ColorDatePickerComponent } from './pages/color-date-picker/color-date-picker.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { GroupDetailsComponent } from './pages/group-details/group-details.component';
import { InvoiceDetailsComponent } from './pages/invoices/invoices-details/invoices-details.component';
import { ProductsComponent } from './pages/products/products.component';
import { UsersComponent } from './pages/users/users.component';
import { SubscriptionEditModalComponent } from './pages/group-details/subscription-edit-modal/subscription-edit-modal.component';
import { ProductsEditModalComponent } from './pages/products/products-edit-modal/products-edit-modal.component';
import { ViewLogsComponent } from './pages/view-logs/view-logs.component';
import { GradeLeadsComponent } from './pages/grade-leads/grade-leads.component';
// import { UpcomingInvoicesComponent } from './pages/upcoming-invoices/upcoming-invoices.component';
import { UpdateCardComponent } from './pages/settings/update-card/update-card.component';
import { DomainsComponent } from './pages/domains/domains.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SkeletonsComponent } from './skeletons/skeletons/skeletons.component';
import { ExportInvoicePdfComponent } from './pages/invoices/export-invoice-pdf/export-invoice-pdf.component';



const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/DD/YY',
  },
  display: {
    dateInput: 'MM/DD/YY',
    monthYearLabel: 'MMM YY',
    dateA11yLabel: 'MM/DD/YY',
    monthYearA11yLabel: 'MMMM YY',
  },
};

@NgModule({
  declarations: [
    UpdateCardComponent,
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    // TextReaderComponent,
    SnackBarComponent,
    ConfirmComponent,
    AlertComponent,
    // SpinnerComponent,
    DashboardAdminComponent,
    HeaderComponent,
    SidebarComponent,
    SettingsComponent,
    FormInputUploadComponent,
    ColorDatePickerComponent,
    UsersComponent,
    InvoicesComponent,
    SubscriptionsComponent,
    GroupDetailsComponent,
    InvoiceDetailsComponent,
    ProductsComponent,
    ModalDeleteComponent,
    SubscriptionEditModalComponent,
    ProductsEditModalComponent,
    isEmptyPipe,
    OTimeAgoPipe,
    DateAgoPipe,
    ViewLogsComponent,
    GradeLeadsComponent,
//    UpcomingInvoicesComponent,
    DomainsComponent,
    SkeletonsComponent,
    ExportInvoicePdfComponent,
  ],
  imports: [
    NgxSkeletonLoaderModule,
    ClipboardModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    FirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule,
    ColorPickerModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    PaginatorModule,
    RippleModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    PanelModule,
    NgxStripeModule.forRoot(environment.defaultStripePublicKey),
  ],
  providers: [
    AppConfigService,
    WhiteLabelService,
    AngularFireModule,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    AuthService,
    AuthProxyService,
    UserService,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    { provide: FIREBASE_OPTIONS, deps: [AppConfigService], useFactory: initializeApp },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    // Date format set for group-details.component.html date picker
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpDomainInterceptor, multi: true },
    FirestoreService,
    StripeService
  ],
  exports: [
    ColorDatePickerComponent,
    FormInputUploadComponent
  ],
  entryComponents: [
    SnackBarComponent,
    ConfirmComponent,
    AlertComponent,
    ModalDeleteComponent,
    SubscriptionEditModalComponent,
    ProductsEditModalComponent,
    UpdateCardComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(appConfig: AppConfigService) {
  const envName = (isDevMode() ? 'DEVELOPMENT' : 'PRODUCTION');
  console.log(`Angular version: ${VERSION.full}`);
  console.log('Mode: ' + envName);

  datadogRum.init({
    applicationId: '077d2ae3-2315-40fa-8238-159a9ef3734d',
    clientToken: 'pubf93fdab5591633c3638fe9533bddbf38',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'map-labs-admin',
    env: envName.toLowerCase(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

  return appConfig.fireConfig();
}

